<template>
	<WSection :sticky="$vuetify.breakpoint.smAndDown" :title="$t('automation.mail_collection')">
		<v-layout column wrap>
			<v-flex mb-3>
				<w-text-info class="mb-3" :text="$t('automation.mail_auto')" />
				<w-text-info :text="$t('automation.magicmail_info')" />
			</v-flex>
			<v-flex>
				<v-layout v-for="(magicMail, index) in magicMails" :key="index" align-center row>
					<ul>
						<li class="ml-5">
							<span>{{ $t(magicMail.description) }} : </span>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn color="primary" small flat v-on="on" @click="copyMail(magicMail.mail)">
										<span class="text-lowercase">{{ magicMail.mail }}</span>
										<v-icon small class="pl-2">far fa-copy</v-icon>
									</v-btn>
								</template>
								<span>{{ $t('automation.copy_address') }}</span>
							</v-tooltip>
						</li>
					</ul>
				</v-layout>
			</v-flex>
		</v-layout>
	</WSection>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'CustomerMagicMails',
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			magicMails: []
		}
	},
	watch: {
		company: {
			handler: function () {
				this.loadMagicMails()
			}
		}
	},
	mounted: function () {
		this.loadMagicMails()
	},
	methods: {
		copyMail (mail) {
			this.appService.copyToClipboard(mail).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$t('automation.messages.email_copied'))
			})
		},
		loadMagicMails () {
			this.service.getMagicMails(this.company.id).then(res => {
				this.magicMails = res
			})
		}
	}
}
</script>
